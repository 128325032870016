
import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'prominentActionButton',
  components: { C360Icon },
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    action: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'add',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // textColor(): string {
    //   let color = 'white';
    //   if (this.disabled && (this.plain || this.outlined)) color = 'grey';
    //   else if (this.disabled) color = 'text-primary';
    //   else if (this.outlined || this.plain) color = 'primary';
    //   return color;
    // },
    buttonColor(): string {
      let color = this.plain ? 'primary' : '';
      if (this.outlined) color = 'white';
      return color;
    },
    minWidth(): string {
      let width = '160';
      if (this.dense) {
        width = '60';
      } else if (this.small) {
        width = '36';
      } else if (this.compact) {
        width = '120';
      }
      return width;
    },
    computedClass(): string {
      let buttonClass = this.plain ? '' : 'primary';
      if (this.outlined && this.disabled) buttonClass = 'bordered-disabled';
      else if (this.outlined) buttonClass = 'bordered';
      else if (this.disabled) buttonClass = 'cursor-not-allowed';
      if (!this.small) buttonClass += ' px-4';
      else buttonClass += ' px-0';
      return buttonClass;
    },
    actionText(): string {
      if (this.next) return 'Next';
      return this.text;
    },
  },
  // TODO most of these these actions need to be handled in parent
  methods: {
    performAction(): void {
      if (this.disabled) return;
      // techdebt should update all places where this button is used
      // and change the action name to match kebab case event to listen for
      switch (this.action) {
        case 'createClient':
          this.$emit('open-create-client-dialog');
          break;
        case 'next':
          this.$emit('go-to-next');
          break;
        case 'downloadIO':
          this.$emit('download-io');
          break;
        case 'openDialog':
          this.$emit('open-dialog');
          break;
        case 'addSlide':
          this.$emit('add-slide');
          break;
        case 'handleUpdateProposal':
          this.$emit('update-proposal');
          break;
        case 'handleUpdateProduct':
          this.$emit('update-product');
          break;
        default:
          this.$emit(this.action);
          break;
      }
    },
  },
});
