
import Vue from 'vue';
import { NewProposalStep } from '@/shared/types';
import { C360ProposalStep } from '@c360/ui';

export default Vue.extend({
  name: 'proposalStepper',
  components: { C360ProposalStep },
  methods: {
    handleRouteClick(step: NewProposalStep): void {
      this.$emit('step-clicked', step);
    },
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    disabledByRequest: {
      type: Boolean,
      default: false,
    },
    isStatusUpdating: {
      type: Boolean,
      default: true,
    },
  },
});
