
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import { Models, Services } from '@/injectables/tokens';
import {
  Status,
  StatusModelContract,
  UXUtilsServiceContract,
  LocalStorageServiceContract,
  FileServiceContract,
  ProposalServiceContract,
  TrackerContract,
  ProposalProductModelContract,
} from '@/injectables';
import { NewProposalStep, Proposal } from '@/shared/types';
import { Modules } from '@/store';

import BaseAppBar from '@/entities/app/base-app-bar.vue';
import WindowResizeProvider from '@/shared/ui/providers/window-resize-provider.vue';
import ProposalStepper from '@/components/Proposal/ProposalStepper.vue';
import ProminentActionButton from '@/components/ProminentActionButton.vue';
import { C360Icon, C360ProposalStatus } from '@c360/ui';
import { ActionButton } from './types';

interface ProposalAppBarData {
  updateStatusLoading: boolean;
  smallScreen: number;
  blocked: boolean;
  approveMenu: boolean;
  delayedIOReady: boolean;
  sendIOLoading: boolean;
  submitOrderLoading: boolean;
}

export default Vue.extend({
  name: 'ProposalAppBarWidget',

  inject: ['$confirm'],

  useInjectable: [
    Models.Status,
    Services.LocalStorage,
    Services.UX,
    Services.Env,
    Services.File,
    Services.Proposal,
    Services.Tracker,
    Models.ProposalProduct,
  ],

  components: {
    BaseAppBar,
    WindowResizeProvider,
    ProposalStepper,
    ProminentActionButton,
    C360ProposalStatus,
    C360Icon,
  },

  props: {
    isAgencyAdmin: {
      type: Boolean,
      required: true,
    },
    isAdminReviewEnabled: {
      type: Boolean,
      required: true,
    },
    IOEmailEnabled: {
      type: Boolean,
      required: true,
    },
    placementIOEnabled: {
      type: Boolean,
      required: true,
    },
    isAdminIoOnly: {
      type: Boolean,
      required: true,
    },
  },

  data: (): ProposalAppBarData => ({
    updateStatusLoading: false,
    smallScreen: 1400,
    blocked: false,
    approveMenu: false,
    delayedIOReady: false,
    sendIOLoading: false,
    submitOrderLoading: false,
  }),

  computed: {
    ...mapGetters(Modules.NewProposal, {
      proposalStepsFactory: 'newProposalSteps',
      isProposalMarketUpdated: 'isProposalMarketUpdated',
      getCurrentStep: 'currentNewProposalStep',
      getPrevStep: 'proposalPreviousStep',
      getNextStep: 'proposalNextStep',
    }),
    ...mapState(Modules.Auth, ['user']),
    ...mapState(Modules.NewProposal, ['saveProgressLoading', 'awaitBudgetCheckLoading']),
    allCreativesConfigured() {
      return this.$store.state.newProposal.newProposal.products.every(product =>
        (this[Models.ProposalProduct] as ProposalProductModelContract).isProductCreativesReadyToSubmit(product),
      );
    },
    isSubmitLocked() {
      return this.saveProgressLoading || !this.allCreativesConfigured;
    },
    route() {
      return this.$route.name;
    },
    proposalStatus(): Status {
      return this.$store.state.newProposal.newProposal.status;
    },
    availableStatuses(): string[] {
      const nextStatuses = this.$store.state.newProposal.newProposal.nextStatuses;

      return (nextStatuses || []).map(s => ({ name: this.prettyStatus(s), value: s }));
    },
    proposalSteps(): NewProposalStep[] {
      return this.proposalStepsFactory({
        routeName: this.route,
        params: { ...this.$route.params },
      });
    },
    skipMainActionsForUser() {
      const statusEntity: StatusModelContract = this.statusEntity;
      statusEntity.currentStatus = this.proposalStatus;
      const { currentStatus } = statusEntity;
      const adminOnlyStatuses = [Status.SubmittedForReview, Status.UnderReview, Status.Approved, Status.Sold];
      return adminOnlyStatuses.includes(currentStatus) && this.isAdminIoOnly && !this.isAgencyAdmin;
    },
    prominentAction(): { text: string; action: string } {
      const routeName = this.route;
      const nextRoutes = ['proposalMarket', 'proposalSolutions', 'proposalSummary'];
      let actionObj = null;
      const nextBehavior = 'next';
      if (nextRoutes.includes(routeName)) {
        actionObj = { text: 'Next', action: nextBehavior };
      } else if (routeName === 'proposalFinalize') {
        actionObj = this.finalizeButtonTextAndAction;
      }
      return actionObj;
    },
    currentStep(): number {
      return this.getCurrentStep(this.route);
    },
    canExportIO(): boolean {
      return this.$store.state.newProposal.newProposal?.products?.length && this.delayedIOReady;
    },
    canDownloadIO(): boolean {
      return this.isAgencyAdmin || this.proposalStatus === Status.Approved;
    },
    canNotApprove(): boolean {
      return this.canSetApproval && this.proposalStatus !== Status.InProgress;
    },
    canSetClientApproval(): boolean {
      const statusEntity: StatusModelContract = this.statusEntity;
      const { enableStatuses } = statusEntity.clientApprovedStatuses({
        adminReviewEnabled: this.isAdminReviewEnabled,
      });
      return enableStatuses.includes(this.proposalStatus);
    },
    canSetApproval(): boolean {
      const statusEntity: StatusModelContract = this.statusEntity;
      const { enableStatuses } = statusEntity.approvedStatuses({
        adminReviewEnabled: this.isAdminReviewEnabled,
      });
      return enableStatuses.includes(this.proposalStatus);
    },
    buttonLoading(): boolean {
      return this.updateStatusLoading || this.sendIOLoading || this.submitOrderLoading;
    },
    isNewProposalBlockedByRequest(): boolean {
      return this.saveProgressLoading || this.awaitBudgetCheckLoading;
    },
    prominentActionIsDisabled(): boolean {
      const { action = '' } = this.prominentAction || {};
      let disabled = false;
      switch (action) {
        case '':
          disabled = true;
          break;
        case 'next':
          disabled = !this.getNextStep(this.route, this.$router.params).canProgress;
          break;
        case 'downloadIO':
          disabled = !this.canExportIO;
          break;
      }
      return disabled;
    },
    submitOrderAvailable() {
      const statusEntity: StatusModelContract = this.statusEntity;

      statusEntity.currentStatus = this.proposalStatus;

      const { currentStatus } = statusEntity;
      return this.placementIOEnabled && currentStatus === Status.Approved;
    },
    finalizeButtonTextAndAction(): { text: string; action: string } {
      const statusEntity: StatusModelContract = this.statusEntity;
      const getApproveAction = () => {
        if (this.IOEmailEnabled)
          return { text: 'Send IO', action: this.actionButton.sendIo, disabled: this.isSubmitLocked };
        return { text: 'Download IO', action: this.actionButton.downloadIO, disabled: this.isSubmitLocked };
      };

      statusEntity.currentStatus = this.proposalStatus;

      const { currentStatus } = statusEntity;

      const reviewRequiredForUser = !this.isAgencyAdmin && this.isAdminReviewEnabled;

      const actions = {
        [Status.InProgress]: reviewRequiredForUser
          ? { text: 'Submit for Review', action: this.actionButton.finalizeButtonClick }
          : { text: 'Approve', action: this.actionButton.openApproveMenu },
        [Status.ClientApproved]: {
          text: 'Submit for Approval',
          action: this.actionButton.finalizeButtonClick,
        },
        [Status.SubmittedForReview]: reviewRequiredForUser
          ? { text: this.prettyStatus(currentStatus), action: '' }
          : this.isAdminReviewEnabled
          ? { text: 'Start Review', action: this.actionButton.finalizeButtonClick }
          : { text: 'Approve', action: this.actionButton.openApproveMenu },
        [Status.UnderReview]: reviewRequiredForUser
          ? { text: this.prettyStatus(currentStatus), action: '' }
          : this.isAdminReviewEnabled
          ? { text: 'Review', action: this.actionButton.openApproveMenu }
          : { text: 'Approve', action: this.actionButton.openApproveMenu },
        [Status.Approved]: getApproveAction(),
        [Status.Sold]: { text: 'Download IO', action: this.actionButton.downloadIO },
      };

      return actions[currentStatus];
    },
    proposalApproved(): boolean {
      return this.proposalStatus === Status.Approved;
    },
    actionButton() {
      return ActionButton;
    },
    prominentActionIcon(): string {
      let icon = 'add';
      if (this.prominentAction?.text.toLowerCase().includes('download')) {
        icon = 'file_download';
      } else if (this.prominentAction?.text.toLowerCase().includes('package') && !this.isSmallerScreen) {
        icon = 'no-icon';
      }
      return icon;
    },
    prominentIcon() {
      const mapper = {
        'download IO': 'Download',
        'Send IO': 'Arrow_Up_Right_MD',
        Review: 'Folder_Check',
        Approve: 'Check',
        'Start Review': 'File_Edit',
      };
      return mapper[this.prominentAction?.text];
    },
    statusConfigs() {
      return {
        statuses: (this.statusEntity as StatusModelContract).statusConfigs(this.isAdminReviewEnabled),
      };
    },
    currentStatus(): { name: string; status: Status; id: number } {
      return { name: 'New Proposal', status: this.proposalStatus, id: Date.now() };
    },
    proposalAppBarKey(): string {
      return `${this.currentStatus.status}_${this.route}`;
    },
  },

  watch: {
    $route() {
      this.closeApproveMenu();
    },
    currentStep(step: number) {
      this.delayedIOReady = false;
      this.prepareIOWithDelay(step);
    },
  },

  async mounted() {
    this.prepareIOWithDelay();
  },

  methods: {
    ...mapActions(Modules.NewProposal, [
      'setChangeStatusNotificationOptions',
      'setInProgressProposal',
      'updateNewProposalStatus',
      'setIsProposalMarketUpdated',
    ]),
    ...mapActions(Modules.Proposal, ['triggerRefetchProposals']),
    ...mapActions(['showSnackbar', 'hideSnackbar']),
    prepareIOWithDelay(step?: number) {
      const current = step ?? this.currentStep;
      if (current < 4) return;
      setTimeout(() => {
        this.delayedIOReady = true;
      }, 1000);
    },
    prettyStatus(status: string): string {
      return (this.statusEntity as StatusModelContract).prettyStatus(status, this.isAdminReviewEnabled).short;
    },
    saveStep(step: NewProposalStep): void {
      const storageService: LocalStorageServiceContract = this.LocalStorageService;
      const uxService: UXUtilsServiceContract = this.uxUtilsService;

      const { email } = this.user;
      const { proposalId } = this.$route.params;

      if (email && proposalId) {
        uxService.setProposalStepForUser({
          email,
          proposalId,
          route: step.route,
          storageService,
        });
      }
    },
    goToPreviousStep(): void {
      const proposalPreviousStep = this.getPrevStep(this.route, { ...this.$router.params });
      if (!proposalPreviousStep?.route?.name) return;
      this.saveStep(proposalPreviousStep);
      const { id: proposalId } = this.$store.state.newProposal.newProposal;
      const { id: clientId } = this.$store.state.client.activeClient;
      this.$router.push({
        name: proposalPreviousStep.route.name,
        params: { id: clientId, proposalId: proposalId || '' },
      });
    },
    async goToNextStep(): Promise<void> {
      const statusEntity: StatusModelContract = this.statusEntity;
      const { triggerRevertWarningStatuses } = statusEntity;
      if (
        this.route !== 'proposalSummary' &&
        triggerRevertWarningStatuses.includes(this.proposalStatus) &&
        this.isProposalMarketUpdated
      ) {
        const result = await this.resetStatusConfirmation();
        if (!result) return;
      }

      const proposalNextStep = this.getNextStep(this.route, { ...this.$router.params });
      if (proposalNextStep.route && proposalNextStep.canProgress) {
        this.saveStep(proposalNextStep);
        this.$router.push({ name: proposalNextStep.route.name, params: proposalNextStep.route.params });
      }
    },
    showApproveMenu(): void {
      this.approveMenu = true;
    },
    closeApproveMenu(): void {
      this.approveMenu = false;
    },
    async saveStatus({
      proposalId = this.$store.state.newProposal.newProposal.id,
      newStatus,
    }: {
      proposalId?: string;
      newStatus: Status;
    }): Promise<boolean> {
      let skipStatusUpdate = false;
      let successfullyUpdated: Proposal | null = null;
      this.updateStatusLoading = true;

      const { id } = this.user;
      const proposal = this.$store.state.newProposal.newProposal;
      const oldStatus = proposal.status;

      if (this.isAdminReviewEnabled && newStatus === Status.ClientApproved) {
        successfullyUpdated = await this.updateNewProposalStatus({
          proposalPropertyId: proposalId,
          newStatus,
        });

        const submittedStatus = Status.SubmittedForReview;

        const { confirmed } = await this.$confirm.show({
          title: 'Submit for Admin Approval?',
          body: `This will change the proposal status to ${this.prettyStatus(submittedStatus)}`,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (confirmed) {
          newStatus = submittedStatus;
        } else {
          skipStatusUpdate = true;
        }
      }

      if (!skipStatusUpdate) {
        successfullyUpdated = await this.updateNewProposalStatus({
          proposalPropertyId: proposalId,
          newStatus,
        });
      }

      if (successfullyUpdated) {
        this.setIsProposalMarketUpdated(false);
        this.triggerRefetchProposals();
      } else {
        return false;
      }

      const clientCategory = successfullyUpdated?.client?.category?.name?.split('^')?.join(', ');

      (this.tracker as TrackerContract).trackProposalStatusChange({
        proposal,
        userId: id,
        updatedAt: new Date().toISOString(),
        from: oldStatus,
        to: newStatus,
        clientCategory,
      });

      this.updateStatusLoading = false;
      return !!successfullyUpdated;
    },
    async setClosedLostStatus(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Update Proposal Status?',
        body: `This will change the proposal status to ${this.prettyStatus(Status.ClosedLost)}`,
        confirmText: 'Yes',
        cancelText: 'No',
      });
      if (!confirmed) return;

      this.saveStatus({ newStatus: Status.ClosedLost });
    },
    setInProgressStatus(): void {
      this.saveStatus({ newStatus: Status.InProgress });
    },
    setApprovedStatus(initialApproval = false): void {
      const newStatus = initialApproval ? Status.ClientApproved : Status.Approved;
      this.saveStatus({ newStatus });
    },
    async downloadIO(): Promise<void> {
      // not sure where the phantom click to this stepper comes from...
      if (!this.canExportIO) return;

      if (
        !this.IOEmailEnabled &&
        this.proposalStatus !== Status.Sold &&
        (this.statusEntity as StatusModelContract).soldStatus === this.proposalStatus
      ) {
        const { confirmed } = await this.$confirm.show({
          title: 'Update Proposal Status?',
          body: 'Do you want to change Proposal status to Sold?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (confirmed) {
          await this.saveStatus({ newStatus: Status.Sold });
        }
      }

      this.showSnackbar({
        content: 'Generating IO, please wait for download to start...',
        color: 'success',
        timeout: -1,
        withLoader: true,
      });

      const { id } = this.$store.state.newProposal.newProposal;

      const timeOffset = new Date().getTimezoneOffset();

      const fileService: FileServiceContract = this.fileService;

      const trackDownloadProgress = () => {
        this.$store.dispatch('hideSnackbar');
      };

      const { isErr, unwrapErr } = await fileService.downloadProposalXlsx(
        {
          id,
          timezoneOffset: timeOffset,
        },
        trackDownloadProgress,
      );

      if (isErr()) {
        this.$log('error', 'appbar/export error', unwrapErr().message);
        this.showSnackbar({
          content: 'error generating IO, please try again',
          color: 'error',
        });
      }
    },
    async sendIO(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Send Insertion Order?',
        body: "Are you sure you want to send this Insertion Order and change this proposal to 'Sold'?",
        confirmText: 'Send',
        cancelText: 'Cancel',
      });

      if (confirmed) {
        this.showSnackbar({
          content: 'Sending IO...',
          color: 'success',
        });
        this.sendIOLoading = true;

        const proposalId = this.$store.state.newProposal.newProposal.id;
        const email = this.$store.state.newProposal.newProposal.createdBy?.email;
        const { isErr, unwrapErr } = await (this.proposalService as ProposalServiceContract).sendIO(proposalId, email);

        if (isErr()) {
          this.$log('error', 'appbar/sendIO error', unwrapErr());
          this.sendIOLoading = false;
          return;
        }

        await this.saveStatus({ newStatus: Status.Sold });

        this.triggerRefetchProposals();

        this.sendIOLoading = false;
      }
    },
    async sendOrder(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Submit Order?',
        body: 'Are you sure you want to submit this order?',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      });

      if (!confirmed) return;
      this.showSnackbar({
        content: 'Submitting Order...',
        color: 'success',
      });
      this.submitOrderLoading = true;

      const proposalId = this.$store.state.newProposal.newProposal.id;
      const { isErr, unwrapErr } = await (this.proposalService as ProposalServiceContract).sendOrder(proposalId);

      if (isErr()) {
        this.$log('error', 'appbar/sendIO error', unwrapErr());
        this.showSnackbar({
          content: 'Something went wrong. Got an error while submitting the order.',
          color: 'error',
        });
        this.submitOrderLoading = false;
        return;
      }

      this.showSnackbar({
        content: 'Order has been successfully submitted',
        color: 'success',
      });

      await this.saveStatus({ newStatus: Status.Sold });

      this.submitOrderLoading = false;
    },
    handleFinalizeAction(actionText: string): void {
      const statusEntity: StatusModelContract = this.statusEntity;

      const { clientApprovedStatuses, underReviewStatuses, approvedStatuses, submittedStatuses } = statusEntity;

      let newStatus = '';

      const { enableStatuses: clientApprovedEnable, targetStatus: clientApprovedTarget } = clientApprovedStatuses({
        adminReviewEnabled: this.isAdminReviewEnabled,
      });

      const { enableStatuses: submittedEnable, targetStatus: submittedTarget } = submittedStatuses({
        adminReviewEnabled: this.isAdminReviewEnabled,
      });

      const { enableStatuses: underReviewEnable, targetStatus: underReviewTarget } = underReviewStatuses({
        adminReviewEnabled: this.isAdminReviewEnabled,
      });

      const { enableStatuses: approvedEnable, targetStatus: approvedTarget } = approvedStatuses({
        adminReviewEnabled: this.isAdminReviewEnabled,
      });

      switch (actionText) {
        case 'Submit for Review':
          if (clientApprovedEnable.includes(this.proposalStatus)) {
            newStatus = clientApprovedTarget;
          }
          break;
        case 'Submit for Approval':
          if (submittedEnable.includes(this.proposalStatus)) {
            newStatus = submittedTarget;
          }
          break;
        case 'Start Review':
          if (underReviewEnable.includes(this.proposalStatus)) {
            newStatus = underReviewTarget;
          }
          break;
        case 'Approve':
          if (approvedEnable.includes(this.proposalStatus)) {
            newStatus = approvedTarget;
          }
          break;
      }

      if (newStatus && newStatus !== this.proposalStatus) {
        this.saveStatus({ newStatus });
      }
    },
    async resetStatusConfirmation() {
      return (
        await this.$confirm.show({
          title: ' Saving Changes will revert proposal back to In-Progress.',
          body: ' Are you sure you want to Save?',
          confirmText: 'Save',
          cancelText: 'Close',
        })
      ).confirmed;
    },
    async onStepClick(step: NewProposalStep): Promise<void> {
      const statusEntity: StatusModelContract = this.statusEntity;
      const { triggerRevertWarningStatuses } = statusEntity;
      if (!step.disabled && this.route !== step.route.name) {
        if (
          triggerRevertWarningStatuses.includes(this.proposalStatus) &&
          !['proposalSummary', 'proposalFinalize'].includes(this.route) &&
          this.isProposalMarketUpdated
        ) {
          const result = this.resetStatusConfirmation();
          if (!result) return;
        }
        this.saveStep(step);
        this.$router.push({
          name: step.route.name,
          params: step.route.params,
        });
      }
    },
  },
});
