
import Vue from 'vue';
import { mapActions } from 'vuex';
import { InputValidationRules } from 'vuetify';

import {
  ValidationServiceContract,
  CalendarServiceContract,
  StatusModelContract,
  ProposalProductModelContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import ClientLogo from './ClientLogo.vue';
import SetAndSaveDefaultsMenu from './SetAndSaveDefaultsMenu.vue';
import ClientInfoDialog from '../Clients/ClientInfoDialog.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { DateRangePicker } from '@/shared/ui';

export default Vue.extend({
  name: 'ProposalSubHeader',

  useInjectable: [Models.Date, Models.ProposalProduct],

  inject: ['$confirm'],

  components: {
    ClientLogo,
    ClientInfoDialog,
    SetAndSaveDefaultsMenu,
    WrapperWithTooltip,
    DateRangePicker,
  },

  props: {
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    menu: boolean;
    valid: boolean;
    goals: string[];
    dates: string[];
    budgetNotificationTriggerValue: number;
    budgetIsFocused: boolean;
    localBudget: number | null;
    showClientInfo: boolean;
    proposalDataUpdated: boolean;
    datesHaveBeenUpdated: boolean;
    localProposal: {
      name?: string;
      goal?: string;
      secondaryGoal?: string;
      budget?: number;
    };
  } => ({
    datesHaveBeenUpdated: false,
    showClientInfo: false,
    proposalDataUpdated: false,
    localBudget: 0,
    budgetIsFocused: false,
    menu: false,
    dates: [],
    goals: [
      'Ad Engagement',
      'General Brand Awareness',
      'Phone Call',
      'Promotions/Special Event',
      'Reach',
      'Request for Information/Lead Generation',
      'Store Visit/Foot Traffic',
      'Web Purchase',
      'Website Traffic',
    ],
    budgetNotificationTriggerValue: 0,
    valid: true,
    localProposal: {},
  }),

  watch: {
    endDate(): void {
      this.setDates();
    },
  },

  computed: {
    goalItems(): string[] {
      return this.goals.filter((g: string) => g !== this.secondaryGoal);
    },
    secondaryGoalItems(): string[] {
      return this.goals.filter((g: string) => g !== this.goal);
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);
      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    hasUnallocatedBudget(): boolean {
      const selectedProducts = this.$store.state.newProposal.newProposal?.products || [];
      return (
        this.$route.name === 'proposalSolutions' &&
        !!selectedProducts.length &&
        selectedProducts.some(p => p?.name && p?.id) &&
        !!this.$store.getters['newProposal/newProposalBudgetUnallocated']
      );
    },
    changeStatusNotificationOptions(): boolean {
      return this.$store.getters['newProposal/changeStatusNotificationOptions'];
    },
    showStatusNotification(): boolean {
      return (
        this.shouldTriggerRevertWarningStatuses &&
        (this.proposalDataUpdated || this.changeStatusNotificationOptions.value)
      );
    },
    proposalStatus(): { status: string; icon: string } | null {
      return this.$store.state.newProposal.newProposal.status;
    },
    readOnly(): boolean {
      return this.$route.name === 'proposalFinalize';
    },
    oneThousandDays(): string {
      const calendarService: CalendarServiceContract = this.$container.get(Services.Calendar);
      return calendarService.dateAfter(1000, new Date()).toISOString();
    },
    clientId(): string | null {
      const { id } = this.$route.params;
      return id;
    },
    clientName(): string {
      const client = this.$store.getters['client/activeClient'];
      return client?.name ?? '';
    },
    prettyBudget(): string {
      return this.$options.filters.formatPrice(this.budget);
    },
    budgetIsDisabled(): boolean {
      return (
        (this[Models.ProposalProduct] as ProposalProductModelContract).allProductsLocked(
          this.$store.state.newProposal.newProposal.products,
        ) && !this.hasUnallocatedBudget
      );
    },
    name: {
      get(): string {
        return this.localProposal.name ?? this.$store.state['newProposal'].newProposal.name;
      },
      set(newName: string): void {
        if (this.shouldTriggerRevertWarningStatuses) {
          this.$set(this.localProposal, 'name', newName);
        } else {
          this.$store.dispatch('newProposal/updateNewProposalName', newName);
        }
      },
    },
    goal: {
      get(): string {
        return this.localProposal.goal ?? this.$store.state['newProposal'].newProposal.goal;
      },
      set(goal: string): void {
        if (this.shouldTriggerRevertWarningStatuses) {
          this.$set(this.localProposal, 'goal', goal);
        } else {
          this.$store.dispatch('newProposal/updateNewProposalGoal', goal);
        }
      },
    },
    secondaryGoal: {
      get(): string {
        return this.localProposal.secondaryGoal ?? this.$store.state['newProposal'].newProposal.secondaryGoal;
      },
      set(secondaryGoal: string): void {
        if (this.shouldTriggerRevertWarningStatuses) {
          this.$set(this.localProposal, 'secondaryGoal', secondaryGoal);
        } else {
          this.$store.dispatch('newProposal/updateNewProposalSecondaryGoal', secondaryGoal);
        }
      },
    },
    startDate: {
      get(): string {
        const { startDate = '', CampaignStartDate = '' } = this.$store.state.newProposal.newProposal;
        return startDate || CampaignStartDate;
      },
    },
    endDate: {
      get(): string {
        const { endDate = '', CampaignEndDate = '' } = this.$store.state.newProposal.newProposal;
        return endDate || CampaignEndDate;
      },
    },
    budget: {
      get(): number {
        const budget = this.localProposal.budget ?? this.$store.state['newProposal'].newProposal?.budget;
        try {
          if (typeof budget === 'string') {
            return parseInt(budget, 10);
          } else if (typeof budget === 'number') {
            return budget;
          }
          return 0;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          return 0;
        }
      },
      set(val: number): void {
        this.proposalDataUpdated = true;
        const newBudget = Math.min(Math.max(val, this.budgetMinimum), this.budgetMaximum);

        if (val) {
          if (this.shouldTriggerRevertWarningStatuses) {
            this.$set(this.localProposal, 'budget', newBudget);
            this.handleUpdateProposal({ budget: val });
          } else {
            this.$store
              .dispatch('newProposal/updateNewProposalBudget', newBudget)
              .then(() => this.$store.dispatch('newProposal/removeProductIncreasedRecommendations'))
              .then(() => this.handleUpdateProposal({ budget: val }))
              .catch(err => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          }
        }
      },
    },
    loading(): boolean {
      return (
        this.$store.state['newProposal'].saveProgressLoading || this.$store.state['newProposal'].awaitBudgetCheckLoading
      );
    },
    budgetMinimum(): number {
      return Math.max(
        (this[Models.ProposalProduct] as ProposalProductModelContract).getMinSolutionBudget(
          this.$store.state.newProposal.newProposal.products,
        ),
        1000,
      );
    },
    budgetMaximum(): number {
      return this.$store.getters['newProposal/proposalBudgetMax'];
    },
    budgetRules(): InputValidationRules {
      return [
        () =>
          this.budget >= this.budgetMinimum ||
          `Budget must be at least ${this.$options.filters.formatPrice(this.budgetMinimum)}`,
        () =>
          this.budget <= this.budgetMaximum ||
          `Budget must not exceed ${this.$options.filters.formatPrice(this.budgetMaximum)}`,
      ];
    },
    isElsyEnabled() {
      return this.$store.state['newProposal'].newProposal?.elsyEnabled;
    },
    shouldTriggerRevertWarningStatuses(): boolean {
      const statusEntity: StatusModelContract = this.$container.get(Models.Status);
      const { triggerRevertWarningStatuses } = statusEntity;

      return triggerRevertWarningStatuses.includes(this.proposalStatus) && !this.readOnly;
    },
    proposalInfo() {
      return {
        name: this.name,
        goal: this.goal,
        secondaryGoal: this.secondaryGoal,
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
  },

  methods: {
    ...mapActions({
      sendProposal: 'newProposal/sendProposal',
      changeProposalBudget: 'newProposal/changeProposalBudget',
    }),
    handleUpdateName(): void {
      if (this.$refs['proposalNameField'].valid) {
        this.handleUpdateProposal({ name: this.name });
      }
    },
    async handleUpdateProposal(propUpdate: { [prop: string]: string | number }) {
      if (this.shouldTriggerRevertWarningStatuses && !this.readOnly) {
        this.proposalDataUpdated = true;
      }
      if (this.showStatusNotification) {
        const { confirmed } = await this.$confirm.show({
          title: ' Saving Changes will revert proposal back to In-Progress.',
          body: ' Are you sure you want to Save?',
          confirmText: 'Save',
          cancelText: 'Close',
        });
        if (!confirmed) return this.resetFields();
      }
      this.saveDraft(propUpdate);
    },
    resetFields() {
      Object.keys(this.localProposal).forEach(key => {
        this.$set(this.localProposal, key, this.$store.state['newProposal'].newProposal[key]);
      });

      this.setDates();
    },
    openClientInfoDialog(): void {
      this.showClientInfo = true;
    },
    closeClientInfoDialog(): void {
      this.showClientInfo = false;
    },
    fetchProductRecommendations(): void {
      const shouldGetRecommendations =
        this.$route.name === 'proposalSolutions' &&
        !this.$store.state['newProposal'].hasReceivedRecommendations &&
        this.$store.getters['newProposal/canGetRecommendations'];
      if (shouldGetRecommendations && !this.isElsyEnabled) {
        this.$store.dispatch('newProposal/fetchProductRecommendations').catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
      }
    },
    handleBudgetFocus(): void {
      if (this.readOnly) {
        if (this.$refs?.sideNavBudget) {
          this.$refs.sideNavBudget.blur();
        }
        return;
      }
      if (this.$refs?.sideNavBudget) {
        this.$refs.sideNavBudget.resetValidation();
      }
      this.localBudget = this.budget > 0 ? this.budget : null;
      this.budgetIsFocused = true;
    },
    handleBudgetBlur(): void {
      if (this.readOnly) return;
      this.budgetIsFocused = false;
      if (this.localBudget !== this.budget) {
        this.budget = this.localBudget;
      }
    },
    async saveDraft(propUpdate: { [prop: string]: string | number }): Promise<void> {
      if (this.readOnly) return;

      if (propUpdate.budget !== undefined) {
        return this.updateBudget(propUpdate.budget);
      }

      if (propUpdate.dates) {
        return this.saveAndSortDates();
      }

      const payload = {
        ...this.proposalInfo,
        ...propUpdate,
      };

      this.sendProposal(payload)
        .then(proposal => {
          if (proposal) {
            this.fetchProductRecommendations();
            this.proposalDataUpdated = false;
            this.datesHaveBeenUpdated = false;
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('proposalSubHeader', 'saveDraft', payload, err);
          this.$store.dispatch('showSnackbar', {
            content: 'Unable to update proposal at this time',
            color: 'warning',
          });
        });
    },
    setDates(): void {
      if (this.startDate && this.endDate) {
        this.dates = [this.startDate, this.endDate];

        this.datesHaveBeenUpdated = false;
      }
    },
    cancelDatesClick(): void {
      this.setDates();
      this.menu = false;
    },
    dateFormatter(date: Date) {
      const calendarService: CalendarServiceContract = this.$container.get(Services.Calendar);
      return calendarService.dateFormatter(date);
    },
    onDateRangeInput() {
      this.datesHaveBeenUpdated = true;
      if (this.dates.length < 2) return;
      this.handleUpdateProposal({ dates: true });
    },
    updateBudget(budget) {
      this.changeProposalBudget(budget)
        .then(proposal => {
          if (proposal) {
            this.fetchProductRecommendations();
          }
          this.proposalDataUpdated = false;
          this.datesHaveBeenUpdated = false;
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('proposalSubHeader', 'saveDraft', err);
          this.$store.dispatch('showSnackbar', {
            content: 'Unable to update proposal at this time',
            color: 'warning',
          });
        });
    },
    saveAndSortDates(bypass: boolean): void {
      if (this.shouldTriggerRevertWarningStatuses && !bypass) {
        this.proposalDataUpdated = true;
      }
      this.dates.sort((a, b) => Date.parse(a) - Date.parse(b));

      const [start, end] = this.dates;
      const datesObj = {
        ...this.proposalInfo,
        startDate: start,
        endDate: end,
      };
      this.sendProposal(datesObj)
        .then(proposal => {
          if (proposal) {
            this.datesHaveBeenUpdated = false;
            this.datesHaveBeenUpdated = false;
            this.$store.dispatch('newProposal/fetchProductConfigs', { force: false });
            this.$store
              .dispatch('newProposal/removeProductIncreasedRecommendations')
              .then(() => this.fetchProductRecommendations())
              .catch(err => {
                // eslint-disable-next-line no-console
                console.log('proposalSubHeader', 'removeProductIncreasedRecommendations', err);
              });
          } else {
            throw new Error('Received unexpected response from server when updating proposal dates.');
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('proposalSubHeader', 'save dates', err);
          this.$store.dispatch('showSnackbar', {
            content: 'Unable to update dates at this time',
            color: 'warning',
          });
        });
    },
  },

  async mounted(): Promise<void> {
    this.setDates();
  },
});
