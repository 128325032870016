
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

import { Models, Services } from '@/injectables/tokens';
import { LocalStorageServiceContract, NewProposalModelContract } from '@/injectables';
import { Modules } from '@/store';

import ProposalSubHeader from '@/components/Proposal/ProposalSubHeader.vue';
import AppBar from '@/widgets/proposal/proposal-app-bar.vue';
import { Routes } from '@/router/routes';

interface Data {
  innerNavigation: number;
  loading: boolean;
}

export default Vue.extend({
  name: 'proposal',

  components: { ProposalSubHeader, AppBar },

  useInjectable: [Models.NewProposal, Services.LocalStorage],

  data: (): Data => ({
    innerNavigation: 1,
    loading: false,
  }),

  watch: {
    proposalId() {
      this.initialProposal(true);
    },
    async clientId() {
      this.initialProposal(true);
    },
  },

  computed: {
    ...mapGetters(Modules.Auth, ['isAgencyAdmin']),
    ...mapState(Modules.Client, ['activeClientId']),
    ...mapState(Modules.Agency, {
      currentAgency: 'currentAgencyInfo',
    }),
    isChangeDisabled(): boolean {
      return (
        (this.newProposalEntity as NewProposalModelContract).newProposalIsChangeDisabled({
          proposalStatus: this.$store.state.newProposal.newProposal.status,
          isAgencyAdmin: this.isAgencyAdmin,
        }) || this.loading
      );
    },
    proposalId() {
      const { proposalId } = this.$route.params;
      return proposalId;
    },
    clientId() {
      const { id } = this.$route.params;
      return id;
    },
  },

  mounted() {
    this.innerNavigation = parseInt(
      (this.LocalStorageService as LocalStorageServiceContract).getItem('mp-proposal-innerNav') || '1',
      10,
    );
    this.initialProposal();
  },

  beforeRouteUpdate(from, to, next) {
    this.innerNavigation += 1;
    (this.LocalStorageService as LocalStorageServiceContract).setItem(
      'mp-proposal-innerNav',
      this.innerNavigation.toString(),
    );
    next();
  },

  methods: {
    ...mapActions(Modules.Output, ['resetOutputAndProposal']),
    ...mapActions(Modules.NewProposal, ['setInProgressProposal', 'fetchProductConfigs']),
    ...mapActions(Modules.Client, { getClient: 'getClientById' }),
    onCancel() {
      (this.LocalStorageService as LocalStorageServiceContract).setItem('mp-proposal-innerNav', '1');
      if (window.history.length < 3 || window.history.length < this.innerNavigation) {
        this.$router.push({ name: Routes.Home });
        return;
      }

      this.$router.go(this.innerNavigation * -1);
    },
    async initialProposal(forceUpdate = false) {
      this.loading = true;
      if (Boolean(this.storeProposalId) && this.storeProposalId !== this.proposalId) {
        this.resetOutputAndProposal({ routeName: '' });
      }

      await this.getClient(this.clientId);

      await this.setInProgressProposal({
        proposalId: this.proposalId,
        PRPID: this.clientId,
        forceUpdate,
      });

      this.fetchProductConfigs({ force: true });
      this.loading = false;
    },
  },
});
