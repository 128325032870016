
import Vue from 'vue';

interface DefaultsMenuItem {
  order: number;
  text: string;
  action: () => void;
  isDisabled?: boolean;
}

export default Vue.extend({
  name: 'setAndSaveDefaultsMenu',
  data: (): {
    openDefaultsMenu: boolean;
  } => ({
    openDefaultsMenu: false,
  }),
  computed: {
    loading(): boolean {
      return this.$store.state['newProposal'].clientDefaultsLoading;
    },
    menuByRoute(): DefaultsMenuItem[] {
      if (this.$route.name === 'proposalMarket') {
        return this.marketItems;
      } else if (this.$route.name === 'proposalSolutions') {
        return this.solutionsItems;
      }
      return [];
    },
    solutionsItems(): DefaultsMenuItem[] {
      const products = this.$store.state['newProposal'].newProposal.products;
      const canSaveDefault = products.length && products.some(product => product?.name);
      const items = [
        {
          order: 1,
          text: 'Set as Default',
          action: () => this.setSolutionsDefault(),
          isDisabled: !canSaveDefault,
        },
        {
          order: 2,
          text: 'Load Default',
          action: () => this.loadSolutionsDefault(),
          isDisabled: !this.clientHasDefaultSolutions,
        },
        {
          order: 3,
          text: 'Recommendations',
          action: () => this.fetchProductRecommendations(),
          isDisabled: !this.$store.getters['newProposal/canGetRecommendations'],
        },
        {
          order: 4,
          text: 'Reset',
          action: () => this.resetSolutions(),
          isDisabled: !this.$store.state['newProposal'].newProposal.products?.length,
        },
      ];
      return items.sort((a, b) => a.order - b.order);
    },
    marketItems(): DefaultsMenuItem[] {
      const items = [
        {
          order: 1,
          text: 'Set as Default',
          action: () => this.setMarketDefault(),
          isDisabled: false,
        },
        {
          order: 2,
          text: 'Load Default',
          action: () => this.loadMarketDefault(),
          isDisabled: !this.clientHasDefaultMarket,
        },
        {
          order: 3,
          text: 'Reset',
          action: () => this.resetMarket(),
          isDisabled: false,
        },
      ];
      return items.sort((a, b) => a.order - b.order);
    },
    clientHasDefaultMarket(): boolean {
      // when SetAndSaveDefaultsMenu is added again, will need to ensure proper transformation of market/solutions
      return this.$store.getters['client/activeClient']?.Defaults?.market;
    },
    clientHasDefaultSolutions(): boolean {
      return this.$store.getters['client/activeClient']?.Defaults?.solutions;
    },
  },
  methods: {
    listItemStyle(isDisabled: string): string {
      let style = 'cursor: pointer';
      if (this.loading) {
        style = 'visibility: hidden';
      } else if (isDisabled) {
        style = 'cursor: no-allowed';
      }
      return style;
    },
    closeMenu(): void {
      this.openDefaultsMenu = false;
    },
    fetchProductRecommendations(): void {
      this.$store
        .dispatch('newProposal/fetchProductRecommendations', true)
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    resetSolutions(): void {
      this.$store
        .dispatch('newProposal/updateProducts', { products: [] })
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    resetMarket(): void {
      this.$store
        .dispatch('newProposal/resetMarket')
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    setSolutionsDefault(): void {
      this.$store
        .dispatch('newProposal/setClientDefaultSolutions')
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    setMarketDefault(): void {
      this.$store
        .dispatch('newProposal/setClientDefaultMarket')
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    loadMarketDefault(): void {
      this.$store
        .dispatch('newProposal/loadClientDefaultMarket')
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    loadSolutionsDefault(): void {
      this.$store
        .dispatch('newProposal/loadClientDefaultSolutions')
        .then(() => this.closeMenu())
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
  },
});
